<template>
  <Form :validation-schema="schema" v-slot="{resetForm, handleSubmit}">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-4">
      <div class="relative text-md 2xl:text-xl text-left">
        <label for="nombre" class="" >Nombre artículo: </label>
        <Field
          name="nombre"
          class="form-input w-full mt-2 rounded-md text-md 2xl:text-xl border-gray-500"
          v-model="detalle.nombre"
          placeholder=""
        />
        <ErrorMessage name="nombre" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
      </div>
      <div class="relative text-md 2xl:text-xl text-left">
        <label for="email">Código:</label>
        <Field
          name="codigo"
          class="form-input w-full mt-2 rounded-md text-md 2xl:text-xl border-gray-500"
          v-model="detalle.codigo"
          placeholder=""
        />
        <ErrorMessage name="codigo" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
      </div>
      <div class="relative text-md 2xl:text-xl text-left">
        <label for="cantidad">Cantidad: </label>
        <Field
          type="number"
          name="cantidad"
          class="form-input w-full mt-2 rounded-md text-md 2xl:text-xl border-gray-500"
          v-model="detalle.cantidad"
          placeholder=""
          onkeypress="return /[0-9]/i.test(event.key)"
        />
        <ErrorMessage name="cantidad" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
      </div>
      <div class="relative text-md 2xl:text-xl text-left">
        <label for="precio">Precio unitario:</label>
        <Field
          name="precio"
          v-model="setPrecio"
          class="form-input w-full mt-2 rounded-md text-md 2xl:text-xl border-gray-500"
          placeholder=""
          onkeypress="return /[0-9]/i.test(event.key)"
        />
        <ErrorMessage name="precio" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
      </div>
    </div>
    <div class="flex justify-center mt-4">
      <button
        @click="handleSubmit($event, () => {agregarProducto(resetForm)})"
        class="py-4 px-6 bg-blue-700 text-white text-lg rounded-md hover:bg-blue-600 focus:outline-none focus:bg-gray-500"
      >
      Agregar
      </button>      
      
    </div>
  </Form>

  <table class="rounded m-5 w-5/6 mx-auto bg-gray-200 text-gray-800">
    <tr class="text-left border-b-2 border-gray-300">
      <th class="px-4 py-3">Nombre</th>
      <th class="px-4 py-3">Código</th>
      <th class="px-4 py-3">Unitario</th>
      <th class="px-4 py-3">Cantidad</th>
      <th class="px-4 py-3">Total</th>
      <th class="px-4 py-3"> </th>
    </tr>
    <tr 
      class="text-left bg-gray-100 border-b border-gray-200"
      v-for="(item,index) in productos" :key="index"
    >
      <td class="px-4 py-3"> {{ item.nombre }} </td>
      <td class="px-4 py-3"> {{ item.codigo }} </td>
      <td class="px-4 py-3"> {{ `$ ${item.precio.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")}` }} </td>
      <td class="px-4 py-3"> {{ item.cantidad }} </td>
      <td class="px-4 py-3"> {{ item.total.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.") }} </td>
      <td class="px-4 py-3"> <button class="text-th-secondary hover:text-th-darker font-bold text-left" @click="quitarProducto(index)"> Quitar </button> </td>
      
    </tr> 
  </table>

  <p class="font-semibold text-right mr-12">TOTAL $ {{ totalVenta.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.") }} </p>

  <div class="relative text-md 2xl:text-xl text-left">
    <label for="boleta">Número de boleta: </label>
    <Field
      name="boleta"
      class="form-input w-full mt-2 rounded-md text-md 2xl:text-xl border-gray-500"
      v-model="boleta"
      placeholder=""
    />
    <ErrorMessage name="boleta" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
  </div>
  
  <br/>
  <div class="relative text-md 2xl:text-xl text-left">
    <label for="sucursal"> Método de pago: </label>
    <Field
      name="sucursal"
      as="select"
      class="form-select w-full mt-2 rounded-md text-md 2xl:text-xl border-gray-500"
      onkeypress="return /[0-9.]/i.test(event.key)"
      v-model="metodo"
    >
      <option class="text-gray-300" value="" disabled selected hidden> Seleccione un método </option>
      <option v-for="metodo in metodos" :key="metodo.mp_id" :value="metodo.mp_id"> {{ metodo.mp_metodo }} </option>
    </Field>
    <ErrorMessage name="sucursal" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
  </div>
  <br/>
  <div class="relative text-md 2xl:text-xl text-left">
    <label for="sucursal"> Monto de pago: </label>
    <Field
      name="monto"
      class="form-input w-full mt-2 rounded-md text-md 2xl:text-xl text-right border-gray-500"
      v-model="setMonto"
      placeholder=""
      onkeypress="return /[0-9]/i.test(event.key)"
    />
    <ErrorMessage name="monto" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
  </div>

  <br />
  <p class="font-semibold text-right mr-12">Vuelto $ {{ vuelto.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.") }} </p>
  <br />
  <button class="py-4 px-6 bg-green-700 text-white text-lg rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-500" @click="ingresarVenta"> Ingresar</button>

</template>

<script>
import { getError } from "@/utils/helpers"
import { Field, Form, ErrorMessage } from "vee-validate"
import { object, string, number } from "yup"
import VentaService from "@/services/VentasService"


export default {
  name: "VentasForm",
  setup() {
    const schema = object({
      nombre: string().required('El nombre es obligatorio.'),
      codigo: string().required('El código es obligatorio.'),
      boleta: string().required('El Número de boleta es obligatorio.'),
      cantidad: number().typeError('El valor cantidad debe ser un número.').required('La cantidad es obligatorio.'),
      precio: string().required('El precio es obligatorio.'),
    })

    return {
      schema
    }
  },
  data() {
    return {
      detalle: {
        nombre: '',
        codigo: '',
        cantidad: 1,
        precio: 0
      },
      productos: [],
      metodo: null,
      boleta: '',
      monto: 0
    };
  },
  computed: {
    metodos() {
      return this.$store.getters["metodoPago/metodos"]
    },
    totalVenta() {
      let total = 0;
      let data = this.productos
      if( data ){
        data.forEach(element => {
          total += parseInt(element.total);
        });
      }
      return total;
    },
    vuelto() {
      let vuelto = 0;
      if (this.monto > 0 ) {
        vuelto = this.monto - this.totalVenta;
      }
      return vuelto;
    },
    setPrecio: {
      get: function() {
        return `$ ${this.detalle.precio.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")}`
      },
      set: function(modifiedValue) {
          let newValue = parseFloat(modifiedValue.replace(/[$.]/g, ""));
          
          if (isNaN(newValue)) {
              newValue = 0;
          }
          
          this.detalle.precio = newValue;
      }
    },
    setMonto: {
      get: function() {
        return `$ ${this.monto.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")}`
      },
      set: function(modifiedValue) {
          
          let newValue = parseFloat(modifiedValue.replace(/[$.]/g, ""));
          
          if (isNaN(newValue)) {
              newValue = 0;
          }
          
          this.monto = newValue;
      }
    },
  },
  methods: {
    agregarProducto(resetForm) {
      this.error = null;
      let payload = {
        nombre: this.detalle.nombre,
        codigo: this.detalle.codigo,
        cantidad: this.detalle.cantidad,
        precio: this.detalle.precio,
        total: (this.detalle.precio * this.detalle.cantidad)
      };

      this.productos.push(payload);
      resetForm()
    },
    quitarProducto(index) {
      this.productos.splice(index, 1)
    },
    ingresarVenta() {

      if( this.vuelto < 0 ) {
        this.emitter.emit("notification-set", {
            type: 'error',
            title: 'Error en vuelto',
            text: 'El monto de pago no puede ser inferior al total',
            time: 10000,
            group: 'notifications'
          });
          return;
      }

      let payload = {
        total: this.totalVenta,
        metodo: this.metodo,
        boleta: this.boleta,
        monto: this.monto,
        productos: this.productos
      }

      VentaService.storeVenta(payload)
        .then((response) => {
          this.emitter.emit("notification-set", {
            type: 'success',
            title: response.data.title,
            text: response.data.message,
            time: 10000,
            group: 'notifications'
          });
          this.productos = [];
        })
        .catch((error) => (this.error = getError(error)));
    },
  },
  props: {

  },
  mounted(){
    if (!this.$store.getters.metodos) {
      this.$store.dispatch("metodoPago/getMetodos")
    }
  },
  components: {
    Field,
    Form,
    ErrorMessage
  }
};
</script>